import React from "react"
import PropTypes from "prop-types"

import ProductsProvider from "./ProductsProvider"

import AnimationRevealPage from "./helpers/AnimationRevealPage.js"
import Hero from "./treact/hero/BackgroundAsImageWithCenteredContent.js"
import TopProducts from "./treact/blogs/ThreeColSimpleWithImage.js"
import Highlights from "./treact/features/VerticalWithAlternateImageAndText.js"
import AboutUs from "./treact/testimonials/TwoColumnWithImage.js"
import ContactUsForm from "./treact/forms/SimpleContactUs.js"
import Footer from "./treact/footers/MiniCenteredFooter.js"

export const LandingPage = ({ children }) => (
  <AnimationRevealPage>
    {children}
    <Hero />
    <ProductsProvider>
      <TopProducts />
    </ProductsProvider>
    <Highlights/>
    <AboutUs />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
)
LandingPage.propTypes = {
  children: PropTypes.node.isRequired,
}
