import React from "react"

// import StoreLayout from "../components/StoreLayout"
// import Products from "../components/Products"
import SEO from "../components/SEO"
import { LandingPage } from "../components/LandingPage"
import HeroImg from "../images/bg.jpg"

const IndexPage = () => (
  // <StoreLayout>
  <LandingPage>
    <SEO
      image={HeroImg}
      keywords={[
        `mint`,
        `fox`,
        `studio`,
        `kniting`,
        `handmade`,
        `knited lingerie`,
        `knited underware`,
        `knited swimsuite`,
        `accesories`,
        `Slava Kozachok`,
        `mintfox`,
      ]}
    />
    {/* <Products></Products> */}
  </LandingPage>
  // </StoreLayout>
)

export default IndexPage
