import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
// import SvgDotPatternIcon from "../../../images/dot-pattern.svg"
import { SectionHeading as HeadingTitle } from "../misc/Headings.js"
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js"

const Container = tw.div`relative`

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const HeadingInfoContainer = tw.div`flex flex-col items-center`
// const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
])
// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded-xl md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-64 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
// ]);
const VideoContainer = tw.div`w-full md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 mx-0 md:mx-4 lg:mx-8`

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded-xl`}
  iframe {
    ${tw`rounded-xl bg-black shadow-xl`}
  }
`
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`
const Title = tw.h4`text-3xl font-bold text-gray-900`
const Description = tw.p`mt-2 text-sm leading-loose`
// const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

// const SvgDotPattern1 = tw(props => (
//   <SvgDotPatternIcon {...props} />
// ))`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`
// const SvgDotPattern2 = tw(props => (
//   <SvgDotPatternIcon {...props} />
// ))`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`
// const SvgDotPattern3 = tw(props => (
//   <SvgDotPatternIcon {...props} />
// ))`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`
// const SvgDotPattern4 = tw(props => (
//   <SvgDotPatternIcon {...props} />
// ))`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`

export default () => {
  const cards = [
    {
      url: "https://www.youtube.com/embed/FF7JrcKS26o?controls=0",
      subtitle: "Useful tips",
      title: "How to take measurements",
      description:
        "Accurate measurement helps to ensure the perfect fit for every body. Here is a quick video instruction about the required measurements and the way to take them right.",
    },
    {
      url: "https://www.youtube.com/embed/DfMqashcdUc?controls=0&showinfo=0",
      subtitle: "This is how we do it",
      title: "Our work - our passion",
      description:
        "We love what we do. From the first loop till your pleasure.",
    },

    // {
    //   url:
    //     "https://images.unsplash.com/photo-1543423924-b9f161af87e4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
    //   subtitle: "Free",
    //   title: "Rock In Rio, Upstate",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    // },

    // {
    //   url:
    //     "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
    //   subtitle: "Exclusive",
    //   title: "Lollapalooza, Manhattan",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    // },
  ]

  return (
    <div id="highlights">
      <Container>
        <SingleColumn>
          <HeadingInfoContainer>
            <HeadingTitle>Highlights</HeadingTitle>
            {/* <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription> */}
          </HeadingInfoContainer>

          <Content>
            {cards.map((card, i) => (
              <Card key={i} reversed={i % 2 === 0}>
                {/* <Image imageSrc={card.imageSrc} /> */}
                <VideoContainer>
                  <StyledResponsiveVideoEmbed
                    url={card.url}
                    background="transparent"
                  />
                </VideoContainer>
                <Details>
                  <Subtitle>{card.subtitle}</Subtitle>
                  <Title>{card.title}</Title>
                  <Description>{card.description}</Description>
                  {/* <Link href={card.url}>See Event Details</Link> */}
                </Details>
              </Card>
            ))}
          </Content>
        </SingleColumn>
        {/* <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 /> */}
      </Container>
    </div>
  )
}
