import React from "react"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js"
import QuotesLeftIcon from "../../../images/quotes-l.svg"
import QuotesRightIcon from "../../../images/quotes-r.svg"
import ArrowLeftIcon from "../../../images/arrow-left-2-icon.svg"
import ArrowRightIcon from "../../../images/arrow-right-2-icon.svg"
// import SvgDecoratorBlob1 from "../../../images/svg-decorator-blob-4.svg"
// import SvgDecoratorBlob2 from "../../../images/svg-decorator-blob-5.svg"

import "slick-carousel/slick/slick.css"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`
const HeadingInfoContainer = tw.div`flex flex-col items-center`
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`

const TestimonialSliderContainer = tw.div`mt-24`
const TestimonialSlider = styled(Slider)``
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded-xl flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded-xl`}
  }
`
const Image = tw(Img)`w-full`
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`
const Quote = tw.blockquote`text-center md:text-left font-medium text-lg lg:text-xl xl:text-2xl`
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-600`
const CustomerTitle = tw.p`font-medium text-sm`

const QuotesLeft = tw(props => (
  <QuotesLeftIcon {...props} />
))`w-8 h-8 lg:w-10 lg:h-10 text-primary-600 absolute top-0 left-0`
const QuotesRight = tw(props => (
  <QuotesRightIcon {...props} />
))`w-8 h-8 lg:w-10 lg:h-10 text-primary-600 absolute bottom-0 right-0`

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-600 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
)
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
)

// const DecoratorBlob1 = tw(props => (
//   <SvgDecoratorBlob1 {...props} />
// ))`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`
// const DecoratorBlob2 = tw(props => (
//   <SvgDecoratorBlob2 {...props} />
// ))`absolute w-32 bottom-0 right-0 -z-10 text-beige-500 opacity-15 transform translate-x-2/3 translate-y-8`

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "slava.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const testimonials = [
    {
      imageSrc: data.desktop.childImageSharp.fluid,
      quote: `Hi, my name is Slava (yes, really strange name). 
        
        Originally I am from Ukraine, but currently living in Austin, TX for almost 4 years. 
        Seems I was born with knitting needles and crochet hook in hands. 
        All my life I tried to make something different, special, unique. 
        I used to work at the factory, sewing 40 bags per day, than with wedding dresses and their crazy brides. 
        But it wasn’t me, there was no love, I had no passion. 
        Bosses told me what to do, when and how.
        Now my team is my lovely husband and the precious kiddo. 
        Here is the page with my creations. All of them have a part of myself. 
        So, get some coffee and happy scrolling.`,
      customerName: "Yaroslava Kozachok",
      customerTitle: "Founder, Mint Fox.",

    },
  ]
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>About us</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <Image fluid={testimonial.imageSrc} />
                </ImageContainer>
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft />
                    <Quote>{testimonial.quote}</Quote>
                    <QuotesRight />
                  </QuoteContainer>
                  <CustomerInfo>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  )
}
