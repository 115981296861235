import React, { useContext } from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { ProductsContext } from "../../ProductsProvider"

import tw from "twin.macro"
import styled from "styled-components"
import { SectionHeading as HeadingTitle } from "../misc/Headings.js"
import { topProducts as topIds } from "../../../data/topProducts"
// import SvgDecoratorBlob1 from "../../../images/svg-decorator-blob-1.svg";
// import SvgDecoratorBlob2 from "../../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-20 md:py-24`
const ThreeColumn = tw.div`flex flex-col items-stretch md:flex-row flex-wrap`
const Column = tw.div`mt-24 md:w-1/3`

const HeadingInfoContainer = tw.div`flex flex-col items-center`
// const HeadingDescription = tw.p`mt-4 font-medium text-beige-400 text-center max-w-sm`;

const Card = tw.div`md:mx-4 xl:mx-8 max-w-full md:max-w-xs`
const Image = styled(BackgroundImage)`
  ${tw`bg-cover bg-center h-80 lg:h-64 rounded-default`}
`
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`
const ProductLink = tw(
  Link
)`inline-block mt-2 text-sm text-primary-600 font-bold cursor-pointer transition-default duration-300 border-b-2 border-transparent hover:border-primary-600`

// const DecoratorBlob1 = tw(
//   SvgDecoratorBlob1
// )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
// const DecoratorBlob2 = tw(
//   SvgDecoratorBlob2
// )`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

// eslint-disable-next-line react/display-name
export default () => {
  // const topIds = [
  //   { id: "prod_JCFLjjLmpYzxDj", category: "Underware" },
  //   { id: "prod_JCFLDwP77rZasx", category: "Crop Tops" },
  //   { id: "prod_JCFKPTFtxaN78V", category: "Accessories" },
  // ]
  const { listProducts } = useContext(ProductsContext)
  const products = listProducts()
  const topProducts = topIds.map(({ id, category }) => ({
    ...products.find(p => p.id === id),
    category,
  }))

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Top Products</HeadingTitle>
          {/* <HeadingDescription>Some amazing blog posts that are written by even more amazing people.</HeadingDescription> */}
        </HeadingInfoContainer>
        <ThreeColumn>
          {topProducts.map(
            (product, index) =>
              product.name && (
                <Column key={index}>
                  <Card>
                    <Link to={`/buy/${product.slug}`}>
                      <Image
                        fluid={product.localFiles[0].childImageSharp.fluid}
                      />
                    </Link>
                    <Category>{product.category}</Category>
                    <Title>{product.name}</Title>
                    <ProductLink to={`/buy/${product.slug}`}>
                      Open in Catalog
                    </ProductLink>
                  </Card>
                </Column>
              )
          )}
        </ThreeColumn>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  )
}
