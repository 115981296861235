const prodTopProducts = [
    { id: "prod_JCFLjjLmpYzxDj", category: "Underware" },
    { id: "prod_JCFLDwP77rZasx", category: "Crop Tops" },
    { id: "prod_JCFKPTFtxaN78V", category: "Accessories" },
  ]
const testTopProducts = [
    { id: "prod_JCEdi6DKJCM8r6", category: "Underware" },
    { id: "prod_JCEuFSWQeZuoG9", category: "Crop Tops" },
    { id: "prod_JCFFvpFUmDhcPr", category: "Accessories" },
  ]

export const topProducts = process.env.NODE_ENV === "production" ? prodTopProducts : testTopProducts