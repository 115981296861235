/* eslint-disable react/display-name */
import React from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import Logo from "../../../images/mint-fox_logo_stroke.svg"

import Header, {
  NavLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js"

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      className={className}
      fluid={imageData}
    >
      {children}
    </BackgroundImage>
  )
}

const StyledHeader = styled(props => <Header {...props} />)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-primary-300 hocus:text-primary-300`}
  }
  ${LogoLink} svg {${tw`w-16 mr-3`}}
  ${LogoLink} {${tw`font-light`}}
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`

const Container = styled(Background)`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
`

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-beige-900 opacity-75`

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const Cta = styled.div`
  ${tw`flex flex-col sm:flex-row`}
`
const PrimaryAction = tw(Link)`rounded-full px-8 py-3 mt-10 mx-2 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold bg-primary-600 text-gray-100 shadow-lg focus:outline-none focus:shadow-outline hocus:bg-primary-700 hocus:text-gray-200 hocus:shadow-xl`
const SecondaryAction = tw.a`text-center rounded-full px-8 py-3 mt-4 mx-2 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold bg-gray-100 text-primary-600 shadow-lg focus:outline-none focus:shadow-outline hocus:bg-gray-200 hocus:text-primary-700 hocus:shadow-xl`

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader logo={Logo} />
        <Content>
          <Heading>
            We will knit
            <br />
            your dream
          </Heading>
          <Cta>
            <PrimaryAction to="/catalog">Go to Catalog</PrimaryAction>
            <SecondaryAction href="#contact">Contact Us</SecondaryAction>
          </Cta>
        </Content>
      </HeroContainer>
    </Container>
  )
}
